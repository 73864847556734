
'use strict';

/* eslint-disable no-param-reassign */

import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import { checkDesktopLargeDeviceWidth } from './checkDeviceWidth';
import checkDeviceType from './checkDeviceType';

// key
// el = DOM element
// item = identifier of group ("who-we-are" pertains to "who-we-are" primary nav el and all sub nav els)

// constants
const LISTENER_THROTTLE = 100;
const MOUSE_HOVER_TIMOUT = 100;

export const navBodyScrollingHandler = (pageLoadWindowWidth, pageLoadWindowHeight) => {
  const mobileNav = document.getElementById('nmx-mobile-nav-container');
  const mobileSubnavLevel1 = Array.prototype.slice.call(mobileNav.querySelectorAll('.nmx-subnav'));
  const mobileSubnavLevel2 = Array.prototype.slice.call(mobileNav.querySelectorAll('.nmx-subnav-level2'));
  const bannerEl = document.getElementById('nmx-informational-banner');

  const mobilePrimaryNavHeight = document.getElementById('nmx-nav-primary').clientHeight;
  const mobileUtilityNavHeight = document.getElementById('nav-utility-container').clientHeight;
  const mobileLogoHeadingHeight = document.getElementById('header-logo-heading').clientHeight;
  // const  = document.getElementById('nmx-search-container') // React DOM element not rendered at time JS is loaded, not used.
  const mobileSearchContainerHeight = 82; // document.getElementById('nmx-search-form-container').clientHeight // TODO: Search form not yet rendered to DOM (React)
  const mobileMenuPhysicalHeight = mobilePrimaryNavHeight + mobileUtilityNavHeight + mobileSearchContainerHeight;
  const currentWindowHeight = pageLoadWindowHeight || window.innerHeight;
  const currentWindowWidth = pageLoadWindowWidth || window.innerWidth;
  let informationalBannerHeight;

  let mobileScrollHeightMin = currentWindowHeight - mobileLogoHeadingHeight;
  let mobileSubnavScrollHeightMin = currentWindowHeight - mobileSearchContainerHeight - mobileLogoHeadingHeight;

  // check against active informational banner height
  if (bannerEl !== null) {
    informationalBannerHeight = bannerEl.clientHeight;
    mobileScrollHeightMin -= informationalBannerHeight;
    mobileSubnavScrollHeightMin -= informationalBannerHeight;
  }

  let mobileScrollHeight; let mobileNavScrolling; let mobileSubnavLevel1Els; let mobileSubnavLevel1ElsHeight; let mobileSubnavLevel2Els; let
    mobileSubnavLevel2ElsHeight;

  if (currentWindowHeight > currentWindowWidth) {
    // ==========
    // mobile profile
    // ==========

    // set mobile nav level 1
    if (mobileMenuPhysicalHeight > currentWindowHeight) { // check if mobile menu height shorter than mobile portrait window height (iPhone 5SE)
      mobileScrollHeight = mobileScrollHeightMin;
      if (!mobileNav.querySelector('.nmx-subnav.is-active')) {
        // if subnav level 1 is not active, then proceed with main setting mobileNav scrolling overflowY
        mobileNavScrolling = 'auto';
      }
    } else {
      if (mobileMenuPhysicalHeight) { mobileScrollHeight = mobileScrollHeightMin; }
      mobileNavScrolling = 'hidden';
    }
  } else {
    // ==========
    // mobile landscape
    // ==========

    // set mobile nav level 1
    mobileScrollHeight = mobileScrollHeightMin; // (mobilePrimaryNavHeight - mobileSearchContainerHeight) < mobileScrollHeightMin ? mobileScrollHeightMin : mobileMenuPhysicalHeight
    if (!mobileNav.querySelector('.nmx-subnav.is-active')) {
      // if subnav level 1 is not active, then proceed with main setting mobileNav scrolling overflowY
      mobileNavScrolling = 'auto';
    }
  }

  mobileNav.style.overflowY = mobileNavScrolling;
  mobileNav.style.height = `${mobileScrollHeight}px`;

  // set mobile nav level 2
  mobileSubnavLevel1.forEach((item1) => { // TODO: rename item var
    mobileSubnavLevel1ElsHeight = 0;
    mobileSubnavLevel1Els = item1.parentNode.querySelectorAll('.nmx-subnav > .nmx-nav-link-container');

    mobileSubnavLevel1Els.forEach((mobileSubnavLevel1Item) => {
      mobileSubnavLevel1ElsHeight += mobileSubnavLevel1Item.clientHeight;
    });

    if (mobileSubnavLevel1ElsHeight < mobileSubnavScrollHeightMin) {
      mobileSubnavLevel1ElsHeight = mobileSubnavScrollHeightMin;
      item1.style.overflowY = 'hidden';
    } else {
      mobileSubnavLevel1ElsHeight = mobileSubnavScrollHeightMin;
      if (!mobileNav.querySelector('.nmx-subnav-level2.is-active')) {
        // if subnav level 2 is not active, then proceed with main setting subnav level 1 scrolling overflowY
        item1.style.overflowY = 'auto';
      }
    }
    item1.style.height = `${mobileSubnavLevel1ElsHeight}px`;
  });

  // set mobile nav level 3
  mobileSubnavLevel2.forEach((item2) => { // TODO: rename item var
    mobileSubnavLevel2ElsHeight = 0;
    mobileSubnavLevel2Els = item2.parentNode.querySelectorAll('.nmx-subnav-level2 > .nmx-nav-link-container');

    mobileSubnavLevel2Els.forEach((mobileSubnavLevel2Item) => {
      mobileSubnavLevel2ElsHeight += mobileSubnavLevel2Item.clientHeight;
    });

    if (mobileSubnavLevel2ElsHeight < mobileSubnavScrollHeightMin) {
      mobileSubnavLevel2ElsHeight = mobileSubnavScrollHeightMin;
      item2.style.overflowY = 'hidden';
    } else {
      mobileSubnavLevel2ElsHeight = mobileSubnavScrollHeightMin;
      item2.style.overflowY = 'auto';
    }

    item2.style.height = `${mobileSubnavLevel2ElsHeight}px`;
  });
};

// ===============================
// ARIA Current
// ===============================
// ===============================
// ARIA Current
// ===============================
export const navUrlIdMap = {
  '/': 'header-logo',
  '/who-we-are/': 'nmx-nav-link-primary-who-we-are',
  '/financial-advisor/': 'nmx-nav-link-primary-sub-financial-advisor',
  '/financial-information/': 'nmx-nav-link-primary-sub-our-financial-strength',
  '/who-we-are/sustainability-impact/': 'nmx-nav-link-primary-sub-sustainability-impact-landing',
  '/financial-planning/': 'nmx-nav-link-primary-financial-planning',
  '/retirement-planning/': 'nmx-nav-link-primary-sub-retirement-planning',
  '/retirement-planning/retirement-calculator/': 'nmx-nav-link-primary-sub-level2-retirement-calculator',
  '/college-savings-plans/': 'nmx-nav-link-primary-sub-college-savings-plans',
  '/private-wealth-management/': 'nmx-nav-link-primary-sub-private-wealth-management',
  '/financial-planning/estate-planning/': 'nmx-nav-link-primary-sub-estate-planning',
  '/long-term-care-planning/': 'nmx-nav-link-primary-sub-financial-planning-long-term-care',
  '/financial-planning/business-services/': 'nmx-nav-link-primary-sub-business-services',
  '/insurance/': 'nmx-nav-link-primary-insurance',
  '/life-insurance/': 'nmx-nav-link-primary-sub-life-insurance',
  '/life-insurance/whole-life-insurance/': 'nmx-nav-link-primary-sub-level2-whole-life-insurance',
  '/life-insurance/universal-life-insurance/': 'nmx-nav-link-primary-sub-level2-universal-life-insurance',
  '/life-insurance/variable-universal-life-insurance-vul/': 'nmx-nav-link-primary-sub-level2-variable-universal-life-insurance',
  '/life-insurance/term-life-insurance/': 'nmx-nav-link-primary-sub-level2-term-life-insurance',
  '/life-insurance/life-insurance-calculator/': 'nmx-nav-link-primary-sub-level2-life-insurance-calculator',
  '/disability-insurance/': 'nmx-nav-link-primary-sub-level2-for-individuals',
  '/disability-insurance/physicians-and-dentists-disability-insurance/': 'nmx-nav-link-primary-sub-level2-for-doctors-and-dentists',
  '/disability-insurance/disability-insurance-calculator/': 'nmx-nav-link-primary-sub-level2-disability-insurance-calculator',
  '/annuities/': 'nmx-nav-link-primary-sub-income-annuities',
  '/investments/': 'nmx-nav-link-primary-investments',
  '/investments/brokerage-accounts-services/': 'nmx-nav-link-primary-sub-brokerage-accounts-services',
  '/private-wealth-management-duplicate/': 'nmx-nav-link-primary-sub-investments-private-wealth-management', // intentional dupe
  '/investments/investment-advisory-services/': 'nmx-nav-link-primary-sub-investment-advisory-services',
  '/investments/fixed-annuity-vs-variable-annuity/': 'nmx-nav-link-primary-sub-fixed-annuity-vs-variable-annuity',
  '/life-and-money/market-commentary/': 'nmx-nav-link-primary-sub-investments-market-commentary',
  '/life-and-money/': 'nmx-nav-link-primary-life-and-money',
  '/life-and-money/financial-planning/': 'nmx-nav-link-primary-sub-financial-planning',
  '/life-and-money/investing/': 'nmx-nav-link-primary-sub-investing',
  '/life-and-money/insurance/': 'nmx-nav-link-primary-sub-insurance',
  '/life-and-money/everyday-money/': 'nmx-nav-link-primary-sub-everyday-money',
  '/life-and-money/family-and-work/': 'nmx-nav-link-primary-sub-family-and-work',
  '/life-and-money/market-commentary-duplicate/': 'nmx-nav-link-primary-sub-life-and-money-market-commentary', // intentional dupe
};

// set aria-current attribute on menu item if currently-viewed page is a menu item
export const setAriaCurrent = () => {
  const currentPath = window.location.pathname;
  const currentPageId = navUrlIdMap[currentPath] || navUrlIdMap[`${currentPath}-duplicate`];

  if (currentPageId !== undefined) {
    document.getElementById(currentPageId).setAttribute('aria-current', 'page');
  }
};

export const navScript = (pageLoadWindowWidthParam, pageLoadWindowHeightParam) => {
  // ====================
  // Polyfills
  // ====================

  /**
   * Element.closest() polyfill
   * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
   */
  if (!Element.prototype.closest) {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
    Element.prototype.closest = function (s) {
      const el = this;
      let ancestor = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (ancestor.matches(s)) return ancestor;
        ancestor = ancestor.parentElement;
      } while (ancestor !== null);
      return null;
    };
  }

  /**
   * ForEach and Nodelist polyfill
   * https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
   */
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  /**
   * Includes polyfill
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes#Polyfill
   */
  if (!String.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    String.prototype.includes = function (search, start) {
      'use strict';

      if (search instanceof RegExp) {
        throw TypeError('first argument must not be a RegExp');
      }
      if (start === undefined) { start = 0; }
      return this.indexOf(search, start) !== -1;
    };
  }

  // ====================
  // # Polyfills
  // ====================

  function ready(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }

  // ===============================
  // Skip Nav
  // ===============================

  // TODO: re-examine mobile skipNav functionality

  const activateSkipNav = (nav) => {
    const focusableEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link'));
    focusableEls.forEach((item) => {
      item.addEventListener('focus', throttle(() => {
        if (item === document.activeElement) {
          nav.classList.add('is-active');
        }
      }, LISTENER_THROTTLE));
      item.addEventListener('blur', throttle(() => {
        nav.classList.remove('is-active');
      }, LISTENER_THROTTLE));
    });
  };

  // ===============================
  // Desktop Primary Nav
  // ===============================

  const desktopLargeHorizontalPosition = (primaryNav) => {
    if (primaryNav && document.querySelector('.nmx-header-logo-container') != null) {
      primaryNav.style.marginLeft = `${(document.querySelector('.nmx-nav-primary-container').clientWidth - primaryNav.clientWidth - document.querySelector('.nmx-header-logo-container').clientWidth - document.getElementById('nmx-nav-link-search-container').clientWidth) / 2}px`;
    }
  };

  const getPrevFocusablePrimaryNavEl = (focusableEls, currentFocusableEl) => {
    const prevFocusableElsIndex = (focusableEls.indexOf(currentFocusableEl) - 1) < 0
      ? (focusableEls.length - 1)
      : focusableEls.indexOf(currentFocusableEl) - 1;
    const prevFocusableEl = focusableEls[prevFocusableElsIndex];

    return ({
      el: prevFocusableEl,
      elIndex: prevFocusableElsIndex,
      firstEl: prevFocusableElsIndex === 0,
      lastEl: prevFocusableElsIndex === focusableEls.length - 1,
    });
  };

  const getNextFocusablePrimaryNavEl = (focusableEls, currentFocusableEl) => {
    const nextFocusableElsIndex = (focusableEls.indexOf(currentFocusableEl) + 1) >= focusableEls.length
      ? 0
      : focusableEls.indexOf(currentFocusableEl) + 1;
    const nextFocusableEl = focusableEls[nextFocusableElsIndex];

    return ({
      el: nextFocusableEl,
      elIndex: nextFocusableElsIndex,
      firstEl: nextFocusableElsIndex === 0,
      lastEl: nextFocusableElsIndex === focusableEls.length - 1,
    });
  };

  const getFirstFocusblePrimaryNavEl = (focusableEls) => {
    const firtFocusableElsIndex = 0;
    const firstFocusableEl = focusableEls[firtFocusableElsIndex];
    return ({
      el: firstFocusableEl,
      elIndex: firtFocusableElsIndex,
      firstEl: firtFocusableElsIndex === 0,
      lastEl: firtFocusableElsIndex === focusableEls.length - 1,
    });
  };

  const getLastFocusblePrimaryNavEl = (focusableEls) => {
    const lastFocusableElsIndex = focusableEls.length - 1;
    const lastFocusableEl = focusableEls[lastFocusableElsIndex];
    return ({
      el: lastFocusableEl,
      elIndex: lastFocusableElsIndex,
      firstEl: lastFocusableElsIndex === 0,
      lastEl: lastFocusableElsIndex === focusableEls.length - 1,
    });
  };

  const getPrimaryNavElItem = (primaryNavElItemId) => primaryNavElItemId.substr(('nmx-nav-link-primary-').length);

  const getSubNavElPrimaryNavElItem = (subNavEl) => getPrimaryNavElItem(subNavEl.closest('.nmx-nav-link-primary-container').querySelector('.nmx-nav-link-primary').id);

  const getPrimaryNavItemId = (primaryNavElItem) => `nmx-nav-link-primary-${primaryNavElItem}`;

  const getSubNavElItem = (subNavElItemId) => subNavElItemId.substr(('nmx-nav-link-primary-sub-').length);

  const closeSubNavLevel2 = (subNavItemId) => {
    const elSubNavLevel2 = document.querySelector(`.nmx-subnav-level2-${getSubNavElItem(subNavItemId)}`);
    // document.getElementById(subNavItemId).dataset.subnavActive = false // TODO: use dataset or classlist?
    document.getElementById(subNavItemId).classList.remove('subnav-level2-is-active');
    // document.getElementById(subNavItemId).classList.remove('is-active') // TODO: this was moved here from mouseout, make sure to remove from all instances
    document.getElementById(subNavItemId).setAttribute('aria-expanded', false);
    elSubNavLevel2.classList.remove('is-active');
    document.getElementById(subNavItemId).focus();
    return document.getElementById(subNavItemId);
  };

  // ===============================
  // Desktop Sub Nav (level 1)
  // ===============================
  const openSubNav = (primaryNavItemId) => {
    const elSubNav = document.querySelector(`.nmx-subnav-${getPrimaryNavElItem(primaryNavItemId)}`);
    // document.getElementById(primaryNavItemId).dataset.subnavActive = true // TODO: use dataset or classlist?
    document.getElementById(primaryNavItemId).classList.add('subnav-is-active');
    document.getElementById(primaryNavItemId).setAttribute('aria-expanded', true);
    elSubNav.classList.add('is-active');
    return elSubNav;
  };

  const closeSubNav = (primaryNavItemId) => {
    const elSubNav = document.querySelector(`.nmx-subnav-${getPrimaryNavElItem(primaryNavItemId)}`);
    // document.getElementById(primaryNavItemId).dataset.subnavActive = false // TODO: use dataset or classlist?
    document.getElementById(primaryNavItemId).classList.remove('subnav-is-active');
    // document.getElementById(primaryNavItemId).classList.remove('is-active') // TODO: this was moved here from mouseout, make sure to remove from all instances // TODO: confirm removing this didn't break anything else
    document.getElementById(primaryNavItemId).setAttribute('aria-expanded', false);
    elSubNav.classList.remove('is-active');
    // loop through subnav items looking for any active subnav-level2
    const elSubNavItems = elSubNav.querySelectorAll('.nmx-nav-link-primary-sub');
    elSubNavItems.forEach((item) => {
      if (item.classList.contains('subnav-level2-is-active')) {
        closeSubNavLevel2(item.id);
      }
    });
  };

  const focusPrevSubNavEl = (elSubNav) => {
    const elSubNavItems = elSubNav.querySelectorAll('.nmx-nav-link-primary-sub');
    const elSubNavItemFocused = includes(elSubNavItems, document.activeElement);
    if (elSubNavItemFocused) {
      const indexCurrentSubNav = indexOf(elSubNavItems, document.activeElement);
      const indexPrevSubNav = (indexCurrentSubNav <= 0)
        ? (elSubNavItems.length - 1)
        : (indexCurrentSubNav - 1);
      elSubNavItems[indexPrevSubNav].focus();
    } else {
      elSubNavItems[(elSubNavItems.length - 1)].focus();
    }
  };

  const focusNextSubNavEl = (elSubNav) => {
    const elSubNavItems = elSubNav.querySelectorAll('.nmx-nav-link-primary-sub');
    const elSubNavItemFocused = includes(elSubNavItems, document.activeElement);
    if (elSubNavItemFocused) {
      const indexCurrentSubNav = indexOf(elSubNavItems, document.activeElement);
      const indexNextSubNav = (indexCurrentSubNav >= elSubNavItems.length - 1)
        ? 0
        : (indexCurrentSubNav + 1);
      elSubNavItems[indexNextSubNav].focus();
    } else {
      elSubNavItems[0].focus();
    }
  };

  const focusFirstSubNavEl = (elSubNav) => {
    const elSubNavItems = elSubNav.querySelectorAll('.nmx-nav-link-primary-sub');
    elSubNavItems[0].focus();
  };

  const focusLastSubNavEl = (elSubNav) => {
    const elSubNavItems = elSubNav.querySelectorAll('.nmx-nav-link-primary-sub');
    elSubNavItems[elSubNavItems.length - 1].focus();
  };

  const getSubNavItemId = (subNavElItem) => `nmx-nav-link-primary-sub-${subNavElItem}`;

  const getSubNavLevel2ElSubNavElItem = (subNavLevel2El) => getSubNavElItem(subNavLevel2El.closest('.nmx-nav-link-primary-sub-container').querySelector('.nmx-nav-link-primary-sub').id);

  // ===============================
  // Desktop Sub Nav (level 2)
  // ===============================
  const openSubNavLevel2 = (subNavItemId) => {
    const elSubNavLevel2 = document.querySelector(`.nmx-subnav-level2-${getSubNavElItem(subNavItemId)}`);
    // document.getElementById(primaryNavItemId).dataset.subnavActive = true // TODO: use dataset or classlist?
    document.getElementById(subNavItemId).classList.add('subnav-level2-is-active');
    document.getElementById(subNavItemId).setAttribute('aria-expanded', true);
    elSubNavLevel2.classList.add('is-active');
    return elSubNavLevel2;
  };

  const focusPrevSubNavLevel2El = (elSubNavLevel2) => {
    const elSubNavLevel2Items = elSubNavLevel2.querySelectorAll('.nmx-nav-link-primary-sub-level2');
    const elSubNavLevel2ItemFocused = includes(elSubNavLevel2Items, document.activeElement);
    if (elSubNavLevel2ItemFocused) {
      const indexCurrentSubNavLevel2 = indexOf(elSubNavLevel2Items, document.activeElement);
      const indexPrevSubNavLevel2 = (indexCurrentSubNavLevel2 <= 0)
        ? (elSubNavLevel2Items.length - 1)
        : (indexCurrentSubNavLevel2 - 1);
      elSubNavLevel2Items[indexPrevSubNavLevel2].focus();
    } else {
      elSubNavLevel2Items[(elSubNavLevel2Items.length - 1)].focus();
    }
  };

  const focusNextSubNavLevel2El = (elSubNavLevel2) => {
    const elSubNavLevel2Items = elSubNavLevel2.querySelectorAll('.nmx-nav-link-primary-sub-level2');
    const elSubNavLevel2ItemFocused = includes(elSubNavLevel2Items, document.activeElement);
    if (elSubNavLevel2ItemFocused) {
      const indexCurrentSubNavLevel2 = indexOf(elSubNavLevel2Items, document.activeElement);
      const indexNextSubNavLevel2 = (indexCurrentSubNavLevel2 >= elSubNavLevel2Items.length - 1)
        ? 0
        : (indexCurrentSubNavLevel2 + 1);
      elSubNavLevel2Items[indexNextSubNavLevel2].focus();
    } else {
      elSubNavLevel2Items[0].focus();
    }
  };

  const focusFirstSubNavLevel2El = (elSubNavLevel2) => { // TODO: these functions can probably be consolidated
    const elSubNavLevel2Items = elSubNavLevel2.querySelectorAll('.nmx-nav-link-primary-sub-level2');
    elSubNavLevel2Items[0].focus();
  };

  const focusLastSubNavLevel2El = (elSubNavLevel2) => {
    const elSubNavLevel2Items = elSubNavLevel2.querySelectorAll('.nmx-nav-link-primary-sub-level2');
    elSubNavLevel2Items[elSubNavLevel2Items.length - 1].focus();
  };

  // ===============================
  // ===============================
  // Main Desktop Primary Nav Functionality (all levels)
  // ===============================
  // ===============================

  // Desktop Nav Listener Functions

  let navHoverTimer; let navHoverEnterPrimaryElItem; let navHoverLeavePrimaryElItem; let currentPrimarySubNavEl;

  const desktopPrimaryOffSubnavContainerClickHandler = (e) => {
    const desktopPrimaryNav = document.getElementById('nmx-nav-primary');
    const subNavContainerEls = Array.prototype.slice.call(desktopPrimaryNav.querySelectorAll('.nmx-subnav'));
    const focusableElsPrimary = Array.prototype.slice.call(desktopPrimaryNav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary'));

    // if click is not on any subNavContainer
    if (!includes(subNavContainerEls, e.target)) {
      // then force close all subnavs
      focusableElsPrimary.forEach((item) => {
        closeSubNav(item.id);
      });
    }
  };

  // delay close subNav els
  const activateNavHoverTimer = (el) => {
    navHoverTimer = setTimeout(() => {
      if (el.classList.contains('nmx-nav-link-primary')) {
        closeSubNav(el.id);
      } else if (el.classList.contains('nmx-subnav')) {
        closeSubNav(getPrimaryNavItemId(getSubNavElPrimaryNavElItem(el)));
      }
    }, MOUSE_HOVER_TIMOUT);
  };

  // const desktopPrimarySubnavContainerElsMouseEnterThrottleHandler = throttle(subNavContainerEl => { // TODO: build back in mouse throttle
  const desktopPrimarySubnavContainerElsMouseEnterThrottleHandler = (subNavContainerEl) => {
    // check if mouseover belongs to navHoverLeavePrimaryElItem
    // if item groups match, stop navHoverTimer
    navHoverEnterPrimaryElItem = getSubNavElPrimaryNavElItem(subNavContainerEl);
    if (navHoverLeavePrimaryElItem === navHoverEnterPrimaryElItem) {
      clearTimeout(navHoverTimer);
    }
  }; // , LISTENER_THROTTLE)

  const desktopPrimarySubnavContainerElsMouseEnterHandler = (e) => {
    const subNavContainerEl = e.target;
    desktopPrimarySubnavContainerElsMouseEnterThrottleHandler(subNavContainerEl);
  };

  // const desktopPrimarySubnavContainerElsMouseLeaveThrottleHandler = throttle(subNavContainerEl => { // TODO: build back in mouse throttle
  const desktopPrimarySubnavContainerElsMouseLeaveThrottleHandler = (subNavContainerEl) => {
    navHoverLeavePrimaryElItem = getSubNavElPrimaryNavElItem(subNavContainerEl);
    activateNavHoverTimer(subNavContainerEl);
  }; // , LISTENER_THROTTLE)

  const desktopPrimarySubnavContainerElsMouseLeaveHandler = (e) => {
    const subNavContainerEl = e.target;
    desktopPrimarySubnavContainerElsMouseLeaveThrottleHandler(subNavContainerEl);
  };

  // const desktopPrimaryFocusableElsMouseEnterThrottleHandler = throttle(focusableEl => { // TODO: build back in mouse throttle
  const desktopPrimaryFocusableElsMouseEnterThrottleHandler = (focusableEl) => {
    // hover over primary
    if (focusableEl.classList.contains('nmx-nav-link-primary')) {
      // set pendingNavHoverPrimaryElItem if hover over primaryNavEl
      if (focusableEl.classList.contains('nmx-nav-link-primary')) {
        navHoverEnterPrimaryElItem = getPrimaryNavElItem(focusableEl.id);
        focusableEl.classList.add('has-hover');
      }
      // if item groups match, stop navHoverTimer
      if (navHoverLeavePrimaryElItem === navHoverEnterPrimaryElItem) {
        clearTimeout(navHoverTimer);
      }
    }

    if (focusableEl.classList.contains('nmx-nav-link-primary') && !focusableEl.classList.contains('subnav-is-active')) {
      openSubNav(focusableEl.id);
    }
  }; // , LISTENER_THROTTLE)

  const desktopPrimaryFocusableElsMouseEnterHandler = (e) => {
    const focusableEl = e.target;
    desktopPrimaryFocusableElsMouseEnterThrottleHandler(focusableEl);
  };

  // const desktopPrimaryFocusableElsMouseLeaveThrottleHandler = throttle(focusableEl => { // TODO: build back in mouse throttle
  const desktopPrimaryFocusableElsMouseLeaveThrottleHandler = (focusableEl) => {
    if (focusableEl.classList.contains('nmx-nav-link-primary')) {
      if (focusableEl.classList.contains('nmx-nav-link-primary')) {
        navHoverLeavePrimaryElItem = getPrimaryNavElItem(focusableEl.id);
        focusableEl.classList.remove('has-hover');
      }
      activateNavHoverTimer(focusableEl);
    }
  }; // , LISTENER_THROTTLE)

  const desktopPrimaryFocusableElsMouseLeaveHandler = (e) => {
    const focusableEl = e.target;
    desktopPrimaryFocusableElsMouseLeaveThrottleHandler(focusableEl);
  };

  const desktopPrimaryFocusableElsFocusThrottleHandler = debounce((focusableEl) => {
    const desktopPrimaryNav = document.getElementById('nmx-nav-primary');
    const focusableElsPrimary = Array.prototype.slice.call(desktopPrimaryNav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary'));
    if (focusableEl === document.activeElement) {
      focusableEl.classList.add('has-focus');
      if (includes(focusableElsPrimary, document.activeElement)) {
        focusableEl.classList.add('is-active');
      }
    }
  }, LISTENER_THROTTLE);

  const desktopPrimaryFocusableElsTouchStartThrottleHandler = (e) => {
    e.preventDefault();

    const desktopPrimaryNav = document.getElementById('nmx-nav-primary');
    const focusableElsPrimary = Array.prototype.slice.call(desktopPrimaryNav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary'));
    const focusableEl = e.target;

    if (focusableEl.classList.contains('nmx-nav-link-primary')) {
      navHoverEnterPrimaryElItem = getPrimaryNavElItem(focusableEl.id);

      if (focusableEl.classList.contains('subnav-is-active')) {
        // close submenu if tapping on active primary nav item

        closeSubNav(focusableEl.id);
      } else {
        // force close and remove all focus and hover states on all potentially-open subnavs // TODO: if used more than once, move to function
        focusableElsPrimary.forEach((item) => {
          closeSubNav(item.id);
          item.classList.remove('has-focus');
          item.classList.remove('has-hover');
        });
        openSubNav(focusableEl.id);
      }
    } else {
      focusableEl.click();
    }
  };

  // const desktopPrimaryFocusableElsTouchStartHandler = (e) => {
  //   const focusableEl = e.target
  //   e.preventDefault() // prevents navigation click
  //   desktopPrimaryFocusableElsTouchStartThrottleHandler(focusableEl)
  // }

  const desktopPrimaryFocusableElsFocusHandler = (e) => {
    const focusableEl = e.target;
    desktopPrimaryFocusableElsFocusThrottleHandler(focusableEl);
  };

  const desktopPrimaryFocusableElsBlurThrottleHandler = debounce((focusableEl) => {
    const desktopPrimaryNav = document.getElementById('nmx-nav-primary');
    const focusableElsPrimary = Array.prototype.slice.call(desktopPrimaryNav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary'));
    focusableEl.classList.remove('has-focus');
    if (includes(focusableElsPrimary, focusableEl)) {
      focusableEl.classList.remove('is-active');
    }
    // TODO: remove
  }, LISTENER_THROTTLE);

  const desktopPrimaryFocusableElsBlurHandler = (e) => {
    const focusableEl = e.target;
    desktopPrimaryFocusableElsBlurThrottleHandler(focusableEl);
  };

  const desktopPrimaryFocusableElsKeydownThrottleHandler = debounce((e, focusableEl) => {
    let currentPrimaryNavEl; let pendingFocusableEl; let thisPrimarySubNav; let thisPrimarySubNavLevel2; let currentPrimaryNavActiveSubNav; let thisSubNavEls; let thisSubNavLevel2Els; let focusedNavItem; let focusableElsCharRefined; let
      focusableElsCharRefinedIndex;
    const desktopPrimaryNav = document.getElementById('nmx-nav-primary');
    const focusableElsPrimary = Array.prototype.slice.call(desktopPrimaryNav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary'));

    const tabKeyCode = 9;
    const enterKeyCode = 13;
    const escapeKeyCode = 27;
    const spaceKeyCode = 32;
    const endKeyCode = 35;
    const homeKeyCode = 36;
    const leftArrowKeyCode = 37;
    const upArrowKeyCode = 38;
    const rightArrowKeyCode = 39;
    const downArrowKeyCode = 40;

    // currentPrimaryNavActiveSubNav = false

    // e.preventDefault()

    if (document.documentElement.classList.contains('nmx-no-touch')) {
      if (e.keyCode === tabKeyCode) {
        if (e.shiftKey) {
          // prev primary nav item
          // get current and pending nav els
          if (document.activeElement.classList.contains('nmx-nav-link-primary-sub') || document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
            currentPrimaryNavEl = document.activeElement.closest('.nmx-nav-link-primary-container').querySelector('.nmx-nav-link-primary');
            pendingFocusableEl = getPrevFocusablePrimaryNavEl(focusableElsPrimary, currentPrimaryNavEl);
          } else {
            currentPrimaryNavEl = document.activeElement;
            pendingFocusableEl = getPrevFocusablePrimaryNavEl(focusableElsPrimary, focusableEl);
          }

          // primary and subnav actions
          if (pendingFocusableEl.el.classList.contains('nmx-nav-link-primary') && pendingFocusableEl.lastEl) {
            // back to header logo
            if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
              closeSubNav(currentPrimaryNavEl.id);
            }
            document.getElementById('header-logo').focus();
          } else if (pendingFocusableEl.el.classList.contains('nmx-nav-link-primary') && !pendingFocusableEl.lastEl) {
            if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
              closeSubNav(currentPrimaryNavEl.id);
            }
            pendingFocusableEl.el.focus();
          }
        } else {
          // next primary nav item
          // get current and pending nav els
          if (document.activeElement.classList.contains('nmx-nav-link-primary-sub') || document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
            currentPrimaryNavEl = document.activeElement.closest('.nmx-nav-link-primary-container').querySelector('.nmx-nav-link-primary');
            pendingFocusableEl = getNextFocusablePrimaryNavEl(focusableElsPrimary, currentPrimaryNavEl);
          } else {
            currentPrimaryNavEl = document.activeElement;
            pendingFocusableEl = getNextFocusablePrimaryNavEl(focusableElsPrimary, focusableEl);
          }

          // primary and subnav actions
          if (pendingFocusableEl.el.classList.contains('nmx-nav-link-primary') && pendingFocusableEl.firstEl) {
            // forward to utility nav
            if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
              closeSubNav(currentPrimaryNavEl.id);
            }
            document.getElementById('nmx-nav-link-utility-fafa').focus();
          } else if (pendingFocusableEl.el.classList.contains('nmx-nav-link-primary') && !pendingFocusableEl.firstEl) {
            if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
              closeSubNav(currentPrimaryNavEl.id);
            }
            pendingFocusableEl.el.focus();
          }
        }
      } else if (e.keyCode === enterKeyCode) {
        // enter activates primary menu item with focus on first menu item -OR- if primary nav is open, activates (clicks) subnav menu item
        if (document.activeElement.classList.contains('nmx-nav-link-primary')) {
          thisPrimarySubNav = openSubNav(document.activeElement.id);
          focusNextSubNavEl(thisPrimarySubNav);
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          if (document.activeElement.classList.contains('has-subnav-level2')) {
            // if subnav item has subnav-level2, drill down into subnav level 2
            thisPrimarySubNavLevel2 = openSubNavLevel2(document.activeElement.id);
            focusNextSubNavLevel2El(thisPrimarySubNavLevel2);
            pendingFocusableEl = undefined; // nullify primary and subnav actions
          } else {
            // no subnav-level2, activate link
            e.target.click();
          }
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) { // TODO: revise this to utilize .nmx-nav-link
          e.target.click();
        }
      } else if (e.keyCode === spaceKeyCode) {
        // if focused on nav item, add in space bar support - space bar activates that active menu item.
        if (e.target.classList.contains('nmx-nav-link')) {
          e.target.click();
        }
      } else if (e.keyCode === homeKeyCode) {
        // home places focus on first menu item (in active menu set)
        if (document.activeElement.classList.contains('nmx-nav-link-primary')) {
          currentPrimaryNavEl = document.activeElement;
          pendingFocusableEl = getFirstFocusblePrimaryNavEl(focusableElsPrimary);
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            currentPrimaryNavActiveSubNav = true;
            closeSubNav(currentPrimaryNavEl.id);
          }
          pendingFocusableEl.el.focus();
          if (currentPrimaryNavActiveSubNav) {
            openSubNav(pendingFocusableEl.el.id);
          }
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          thisPrimarySubNav = document.activeElement.closest('.nmx-subnav');
          focusFirstSubNavEl(thisPrimarySubNav);
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
          thisPrimarySubNavLevel2 = document.activeElement.closest('.nmx-subnav-level2');
          focusFirstSubNavLevel2El(thisPrimarySubNavLevel2);
        }
      } else if (e.keyCode === endKeyCode) {
        // end places focus on last menu item (in active menu set)
        if (document.activeElement.classList.contains('nmx-nav-link-primary')) {
          currentPrimaryNavEl = document.activeElement;
          pendingFocusableEl = getLastFocusblePrimaryNavEl(focusableElsPrimary);
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            currentPrimaryNavActiveSubNav = true;
            closeSubNav(currentPrimaryNavEl.id);
          }
          pendingFocusableEl.el.focus();
          if (currentPrimaryNavActiveSubNav) {
            openSubNav(pendingFocusableEl.el.id);
          }
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          thisPrimarySubNav = document.activeElement.closest('.nmx-subnav');
          focusLastSubNavEl(thisPrimarySubNav);
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
          thisPrimarySubNavLevel2 = document.activeElement.closest('.nmx-subnav-level2');
          focusLastSubNavLevel2El(thisPrimarySubNavLevel2);
        }
      } else if (e.keyCode === escapeKeyCode) {
        // escape closes any open subnav - moves focus up one menu level (if available)
        if (document.activeElement.classList.contains('nmx-nav-link-primary')) {
          currentPrimaryNavEl = document.activeElement;
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            currentPrimaryNavActiveSubNav = false;
            closeSubNav(currentPrimaryNavEl.id);
          }
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          currentPrimaryNavEl = document.activeElement.closest('.nmx-nav-link-primary-container').querySelector('.nmx-nav-link-primary');
          currentPrimaryNavActiveSubNav = false;
          closeSubNav(currentPrimaryNavEl.id);
          currentPrimaryNavEl.focus();
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
          currentPrimarySubNavEl = document.activeElement.closest('.nmx-nav-link-primary-sub-container').querySelector('.nmx-nav-link-primary-sub');
          pendingFocusableEl = closeSubNavLevel2(currentPrimarySubNavEl.id);
        }
      } else if (e.keyCode === upArrowKeyCode) {
        // up arrow activates primary menu with focus on last menu item
        if (document.activeElement.classList.contains('nmx-nav-link-primary')) {
          thisPrimarySubNav = openSubNav(document.activeElement.id);
          focusPrevSubNavEl(thisPrimarySubNav);
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          thisPrimarySubNav = document.activeElement.closest('.nmx-subnav');
          focusPrevSubNavEl(thisPrimarySubNav);
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
          thisPrimarySubNavLevel2 = document.activeElement.closest('.nmx-subnav-level2');
          focusPrevSubNavLevel2El(thisPrimarySubNavLevel2);
        }
      } else if (e.keyCode === downArrowKeyCode) {
        // down arrow activates primary menu item with focus on first menu item
        if (document.activeElement.classList.contains('nmx-nav-link-primary')) {
          thisPrimarySubNav = openSubNav(document.activeElement.id);
          focusNextSubNavEl(thisPrimarySubNav);
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          thisPrimarySubNav = document.activeElement.closest('.nmx-subnav');
          focusNextSubNavEl(thisPrimarySubNav);
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
          thisPrimarySubNavLevel2 = document.activeElement.closest('.nmx-subnav-level2');
          focusNextSubNavLevel2El(thisPrimarySubNavLevel2);
        }
      } else if (e.keyCode === leftArrowKeyCode) {
        // on desktop, primary nav els ("menubar") should react to left/right arrow keys
        // get current and pending nav els
        if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
          currentPrimarySubNavEl = document.activeElement.closest('.nmx-nav-link-primary-sub-container').querySelector('.nmx-nav-link-primary-sub');
          closeSubNavLevel2(currentPrimarySubNavEl.id);
          pendingFocusableEl = undefined; // nullify primary and subnav actions
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          currentPrimaryNavEl = document.activeElement.closest('.nmx-nav-link-primary-container').querySelector('.nmx-nav-link-primary');
          pendingFocusableEl = getPrevFocusablePrimaryNavEl(focusableElsPrimary, currentPrimaryNavEl);
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            currentPrimaryNavActiveSubNav = true;
          }
        } else {
          // if primary nav item, reverse to previous Primary Nav Item
          currentPrimaryNavEl = document.activeElement; // TODO: readdress (confirm) if Primary Nav item
          pendingFocusableEl = getPrevFocusablePrimaryNavEl(focusableElsPrimary, focusableEl);
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            currentPrimaryNavActiveSubNav = true;
          }
        }

        // primary and subnav actions
        if (pendingFocusableEl && pendingFocusableEl.el.classList.contains('nmx-nav-link-primary')) {
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            closeSubNav(currentPrimaryNavEl.id);
          }
          pendingFocusableEl.el.focus();
          if (currentPrimaryNavActiveSubNav) {
            openSubNav(pendingFocusableEl.el.id);
          }
        }
      } else if (e.keyCode === rightArrowKeyCode) {
        // on desktop, primary nav els ("menubar") should react to left/right arrow keys
        // get current and pending nav els
        if (document.activeElement.classList.contains('nmx-nav-link-primary-sub-level2')) {
          // if currently on sub nav level 2 item, advance to next Primary Nav Item
          currentPrimaryNavEl = document.activeElement.closest('.nmx-nav-link-primary-container').querySelector('.nmx-nav-link-primary');
          pendingFocusableEl = getNextFocusablePrimaryNavEl(focusableElsPrimary, currentPrimaryNavEl);
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            currentPrimaryNavActiveSubNav = true;
          }
        } else if (document.activeElement.classList.contains('nmx-nav-link-primary-sub')) {
          // if sub nav level 1 item, check if subnav level 2 available
          currentPrimaryNavEl = document.activeElement.closest('.nmx-nav-link-primary-container').querySelector('.nmx-nav-link-primary');
          if (document.activeElement.classList.contains('has-subnav-level2')) {
            // if subnav item has subnav-level2, drill down into subnav level 2
            thisPrimarySubNavLevel2 = openSubNavLevel2(document.activeElement.id);
            focusNextSubNavLevel2El(thisPrimarySubNavLevel2);
            pendingFocusableEl = undefined; // nullify primary and subnav actions
          } else {
            // no subnav-level2, advance to next Primary Nav item
            pendingFocusableEl = getNextFocusablePrimaryNavEl(focusableElsPrimary, currentPrimaryNavEl);
            if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
              currentPrimaryNavActiveSubNav = true;
            }
          }
        } else {
          // if primary nav item, advance to next Primary Nav Item
          currentPrimaryNavEl = document.activeElement; // TODO: readdress (confirm) if Primary Nav item
          pendingFocusableEl = getNextFocusablePrimaryNavEl(focusableElsPrimary, focusableEl);
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            currentPrimaryNavActiveSubNav = true;
          }
        }

        // primary and subnav actions
        if (pendingFocusableEl && pendingFocusableEl.el.classList.contains('nmx-nav-link-primary')) {
          if (currentPrimaryNavEl.classList.contains('subnav-is-active')) {
            closeSubNav(currentPrimaryNavEl.id);
          }
          pendingFocusableEl.el.focus();
          if (currentPrimaryNavActiveSubNav) {
            openSubNav(pendingFocusableEl.el.id);
          }
        }
      } else if (e.keyCode >= 65 && e.keyCode <= 90) {
        // printable character support - move focus to next menu item whose first char begins with that printable character
        focusedNavItem = document.activeElement;
        focusableElsCharRefined = []; // re-init each time

        if (focusedNavItem.classList.contains('nmx-nav-link-primary')) {
          // if focus is on primaryNav item
          // TODO FIX THIS
          // eslint-disable-next-line no-shadow
          focusableElsPrimary.forEach((focusableEl) => {
            if (focusableEl.innerText.substring(0, 1) === String.fromCharCode(e.keyCode)) {
              focusableElsCharRefined.push(focusableEl);
            }
          });
        } else if (focusedNavItem.classList.contains('nmx-nav-link-primary-sub')) {
          // if focus is on subnav item
          thisSubNavEls = focusedNavItem.closest('.nmx-nav-link-primary-container').querySelectorAll('.nmx-nav-link-primary-sub');
          // TODO FIX THIS
          // eslint-disable-next-line no-shadow
          thisSubNavEls.forEach((focusableEl) => {
            if (focusableEl.innerText.substring(0, 1) === String.fromCharCode(e.keyCode)) {
              focusableElsCharRefined.push(focusableEl);
            }
          });
        } else if (focusedNavItem.classList.contains('nmx-nav-link-primary-sub-level2')) {
          // if focus is on subnav item
          thisSubNavLevel2Els = focusedNavItem.closest('.nmx-nav-link-primary-sub-container').querySelectorAll('.nmx-nav-link-primary-sub-level2');
          // TODO FIX THIS
          // eslint-disable-next-line no-shadow
          thisSubNavLevel2Els.forEach((focusableEl) => {
            if (focusableEl.innerText.substring(0, 1) === String.fromCharCode(e.keyCode)) {
              focusableElsCharRefined.push(focusableEl);
            }
          });
        }

        // check if any elements in refined printable character array
        if (focusableElsCharRefined.length > 0) {
          // check for multiple count of printable character array items
          if (focusableElsCharRefined.length > 1) {
            // get index of current-focused item within refined printable character array
            focusableElsCharRefinedIndex = focusableElsCharRefined.indexOf(focusedNavItem);
            // confirm current item (index) exists in refined printable character array
            if (focusableElsCharRefinedIndex >= 0) {
              // if focus is on last item in focusableElsCharRefined, focus on first instance, otherwise, go to next instance
              if (focusableElsCharRefinedIndex === (focusableElsCharRefined.length - 1)) {
                // focus first instance
                focusableElsCharRefined[0].focus();
              } else {
                // focus next instance
                focusableElsCharRefined[focusableElsCharRefinedIndex + 1].focus();
              }
            } else {
              // element in focusableElsCharRefined not yet focused, focus on first item
              focusableElsCharRefined[0].focus();
            }
          } else {
            // only one item available for this character, focus on first item
            focusableElsCharRefined[0].focus();
          }
        } // else no focusableEls at this character
      }
    }
  }, LISTENER_THROTTLE);

  const desktopPrimaryFocusableElsKeydownHandler = (e) => {
    const focusableEl = e.target;
    e.preventDefault();
    desktopPrimaryFocusableElsKeydownThrottleHandler(e, focusableEl);
  };

  // Desktop Nav Activate Primary Function

  const activateDesktopPrimaryNav = (nav, headerLogoContainer, loginButtonContainer) => {
    const focusableEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary, .nmx-nav-link.nmx-nav-link-primary-sub, .nmx-nav-link.nmx-nav-link-primary-sub-level2'));
    const subNavOverviewFocusableEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link.nmx-nav-link-mobile-subnav-overview'));
    const subNavContainerEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-subnav'));
    // let swipedir
    // const currentWindowHeight = window.innerHeight
    // const headerLogoContainer = document.getElementById('header-logo-heading')

    // build desktop logo positioning
    if (!headerLogoContainer.parentElement.classList.contains('nmx-nav-primary-container')) {
      document.querySelector('.nmx-nav-primary-container').insertBefore(headerLogoContainer, nav);
    }

    // build desktop login button positioning
    if (loginButtonContainer && !loginButtonContainer.parentElement.classList.contains('nmx-nav-link-utility-container')) {
      document.getElementById('nmx-nav-link-login-button-container').insertBefore(loginButtonContainer, null);
    }

    // click off subnav container
    document.addEventListener('click', desktopPrimaryOffSubnavContainerClickHandler);

    // mouse listeners for nmx-subnav elements (ULs)
    subNavContainerEls.forEach((item) => {
      item.addEventListener('mouseenter', desktopPrimarySubnavContainerElsMouseEnterHandler);
      item.addEventListener('mouseleave', desktopPrimarySubnavContainerElsMouseLeaveHandler);

      // remove any dynamic mobile nav element traces // TODO: these are currently hard-coded
    });

    // mobile device, desktop viewport, enable overview links
    subNavOverviewFocusableEls.forEach((item) => {
      if (document.documentElement.classList.contains('nmx-no-touch')) {
        item.classList.remove('is-active-desktop');
        item.classList.remove('is-active-desktop-subnav-level2');
      } else {
        item.classList.add('is-active-desktop');
        // TODO: come back and style subnav-level1 and subnav-level2 overview links
        // console.log('item', item.parent)
        // if (item.parent.parent.classList.contains('nmx-subnav-level2')) {
        //   item.classList.add('is-active-desktop-subnav-level2')
        // }
      }
    });

    // keyboard listeners for all primary, subnav and subnav-level2 focusable elements
    focusableEls.forEach((item) => {
      item.addEventListener('mouseenter', desktopPrimaryFocusableElsMouseEnterHandler);
      item.addEventListener('mouseleave', desktopPrimaryFocusableElsMouseLeaveHandler);
      if (!document.documentElement.classList.contains('nmx-no-touch')) {
        // desktop device, desktop viewport

        // } else {
        // mobile device, desktop viewport, disable hover and enable custom touchstart
        item.addEventListener('touchstart', desktopPrimaryFocusableElsTouchStartThrottleHandler);
      }
      item.addEventListener('focus', desktopPrimaryFocusableElsFocusHandler);
      item.addEventListener('blur', desktopPrimaryFocusableElsBlurHandler);
      item.addEventListener('keydown', desktopPrimaryFocusableElsKeydownHandler);
    });
  };

  const deactivateDesktopPrimaryNav = (nav) => {
    const focusableEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary, .nmx-nav-link.nmx-nav-link-primary-sub, .nmx-nav-link.nmx-nav-link-primary-sub-level2'));
    const subNavOverviewFocusableEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link.nmx-nav-link-mobile-subnav-overview'));
    const subNavContainerEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-subnav'));

    // click off subnav container
    document.removeEventListener('click', desktopPrimaryOffSubnavContainerClickHandler);

    // mouse listeners for nmx-subnav elements (ULs)
    subNavContainerEls.forEach((item) => {
      item.removeEventListener('mouseenter', desktopPrimarySubnavContainerElsMouseEnterHandler);
      item.removeEventListener('mouseleave', desktopPrimarySubnavContainerElsMouseLeaveHandler);
    });

    // mobile device, desktop viewport, remove assistive classes
    subNavOverviewFocusableEls.forEach((item) => {
      item.classList.remove('is-active-desktop');
      item.classList.remove('is-active-desktop-subnav-level2');
    });

    // keyboard listeners for all primary, subnav and subnav-level2 focusable elements
    focusableEls.forEach((item) => {
      item.removeEventListener('mouseenter', desktopPrimaryFocusableElsMouseEnterHandler);
      item.removeEventListener('mouseleave', desktopPrimaryFocusableElsMouseLeaveHandler);
      if (!document.documentElement.classList.contains('nmx-no-touch')) {
        // desktop device, desktop viewport

        // } else {
        // mobile device, desktop viewport, disable hover and disable custom touchstart
        item.removeEventListener('touchstart', desktopPrimaryFocusableElsTouchStartThrottleHandler);
      }

      item.removeEventListener('focus', desktopPrimaryFocusableElsFocusHandler);
      item.removeEventListener('blur', desktopPrimaryFocusableElsBlurHandler);
      item.removeEventListener('keydown', desktopPrimaryFocusableElsKeydownHandler);
    });
  };

  // ===============================
  // ===============================
  // Mobile Nav
  // ===============================
  // ===============================

  const mobileNavLevelScrollReset = () => {
    const mobileNav = document.getElementById('nmx-mobile-nav-container');
    mobileNav.scrollTop = 0;
    // setTimeout(document.getElementById('nmx-search-field').focus(), 50); // Forces repaint of mobile search field on Chrome browsers // TODO: get this working
  };

  // Mobile Nav Listener Functions

  const mobilePrimaryFocusableElsClickHandler = (e) => {
    e.preventDefault(); // prevents navigation click
    const mobileNav = document.getElementById('nmx-mobile-nav-container');
    const primaryFocusableEl = e.target;
    primaryFocusableEl.classList.add('subnav-is-active');
    primaryFocusableEl.setAttribute('aria-expanded', true);
    const elSubNav = document.querySelector(`.nmx-subnav-${getPrimaryNavElItem(primaryFocusableEl.id)}`);
    // const elSubNavMobileBackOverlay = elSubNav.querySelector('.nmx-nav-mobile-subnav-bg')
    elSubNav.classList.add('is-active');
    mobileNav.style.overflowY = 'hidden'; // scroll lock mobile nav level 1
    primaryFocusableEl.blur();
    mobileNavLevelScrollReset(); // TODO: once navScroll configured, determine if this is still needed
  };

  const mobileSubnavFocusableElsClickHandler = (e) => {
    e.preventDefault(); // prevents navigation click
    const subnavFocusableEl = e.target;
    subnavFocusableEl.classList.add('subnav-level2-is-active');
    subnavFocusableEl.setAttribute('aria-expanded', true);
    const elSubNavLevel2 = document.querySelector(`.nmx-subnav-level2-${getSubNavElItem(subnavFocusableEl.id)}`);
    // const elSubNavLevel2MobileBackOverlay = elSubNavLevel2.querySelector('.nmx-nav-mobile-subnav-bg')
    elSubNavLevel2.classList.add('is-active');
    subnavFocusableEl.closest('.nmx-subnav').style.overflowY = 'hidden';
    subnavFocusableEl.closest('.nmx-subnav').scrollTop = 0;
    subnavFocusableEl.blur();
    mobileNavLevelScrollReset();
  };

  const mobileSubnavBackElsClickHandler = (e) => {
    const subnavBackFocusableEl = e.target;
    document.getElementById(getPrimaryNavItemId(getSubNavElPrimaryNavElItem(subnavBackFocusableEl))).classList.remove('subnav-is-active');
    document.getElementById(getPrimaryNavItemId(getSubNavElPrimaryNavElItem(subnavBackFocusableEl))).setAttribute('aria-expanded', false);
    const elSubNav = document.querySelector(`.nmx-subnav-${getSubNavElPrimaryNavElItem(subnavBackFocusableEl)}`);
    elSubNav.classList.remove('is-active');
    navBodyScrollingHandler(); // reset primary nav scrolling and reset mobileNav lock if needed
    subnavBackFocusableEl.blur();
    mobileNavLevelScrollReset();
  };

  const mobileSubnavLevel2BackElsClickHandler = (e) => {
    const subnavLevel2BackFocusableEl = e.target;
    document.getElementById(getSubNavItemId(getSubNavLevel2ElSubNavElItem(subnavLevel2BackFocusableEl))).classList.remove('subnav-level2-is-active');
    document.getElementById(getSubNavItemId(getSubNavLevel2ElSubNavElItem(subnavLevel2BackFocusableEl))).setAttribute('aria-expanded', false);
    const elSubNavLevel2 = document.querySelector(`.nmx-subnav-level2-${getSubNavLevel2ElSubNavElItem(subnavLevel2BackFocusableEl)}`);
    elSubNavLevel2.classList.remove('is-active');
    navBodyScrollingHandler(); // reset primary nav scrolling and reset mobileNav lock if needed
    subnavLevel2BackFocusableEl.blur();
    mobileNavLevelScrollReset();
  };

  const mobileTriggerOpenElClickHandler = () => { // TODO: rename to generic trigger click (not open or close), including vars
    const mobileNav = document.getElementById('nmx-mobile-nav-container');
    const mobileTriggerOpen = document.getElementById('nav-toggle');
    const subNavContainerBackEls = Array.prototype.slice.call(mobileNav.querySelectorAll('.nmx-nav-link-mobile-back'));
    const subNavContainerLevel2BackEls = Array.prototype.slice.call(mobileNav.querySelectorAll('.nmx-nav-link-mobile-level2-back'));

    if (mobileTriggerOpen.classList.contains('is-active')) {
      mobileTriggerOpen.classList.remove('is-active');
      mobileNav.classList.remove('is-mobile-active');
      navBodyScrollingHandler(); // reset any scrolling settings if subnavs were utilized
      document.body.classList.remove('is-mobile-active');
      document.documentElement.style.overflow = 'auto';

      // close all subnav
      subNavContainerBackEls.forEach((item) => {
        document.getElementById(getPrimaryNavItemId(getSubNavElPrimaryNavElItem(item))).classList.remove('subnav-is-active');
        document.getElementById(getPrimaryNavItemId(getSubNavElPrimaryNavElItem(item))).setAttribute('aria-expanded', false);
        const elSubNav = document.querySelector(`.nmx-subnav-${getSubNavElPrimaryNavElItem(item)}`);
        elSubNav.classList.remove('is-active');
      });
      // close all subnav-level2
      subNavContainerLevel2BackEls.forEach((item) => {
        document.getElementById(getSubNavItemId(getSubNavLevel2ElSubNavElItem(item))).classList.remove('subnav-level2-is-active');
        document.getElementById(getSubNavItemId(getSubNavLevel2ElSubNavElItem(item))).setAttribute('aria-expanded', false);
        const elSubNavLevel2 = document.querySelector(`.nmx-subnav-level2-${getSubNavLevel2ElSubNavElItem(item)}`);
        elSubNavLevel2.classList.remove('is-active');
      });
    } else { // TODO: investigate if this is even needed (close button and overlay clicks close nav)
      mobileTriggerOpen.classList.add('is-active');
      mobileNav.classList.add('is-mobile-active');
      document.body.classList.add('is-mobile-active');
      document.documentElement.style.overflow = 'hidden';
      setTimeout(() => {
        document.getElementById('nmx-search-field').focus();
      }, LISTENER_THROTTLE);
      mobileNavLevelScrollReset();
    }

    navBodyScrollingHandler(); // reset primary nav scrolling and reset mobileNav lock if needed
  };

  const mobileOpenOverlayElClickHandler = () => {
    const mobileNav = document.getElementById('nmx-mobile-nav-container');
    const mobileTriggerOpen = document.getElementById('nav-toggle');
    const subNavContainerBackEls = Array.prototype.slice.call(mobileNav.querySelectorAll('.nmx-nav-link-mobile-back'));
    const subNavContainerLevel2BackEls = Array.prototype.slice.call(mobileNav.querySelectorAll('.nmx-nav-link-mobile-level2-back'));

    if (mobileTriggerOpen.classList.contains('is-active')) {
      mobileTriggerOpen.classList.remove('is-active');
      mobileNav.classList.remove('is-mobile-active');
      document.body.classList.remove('is-mobile-active');
      document.documentElement.style.overflow = 'auto';

      // close all subnav
      subNavContainerBackEls.forEach((item) => {
        document.getElementById(getPrimaryNavItemId(getSubNavElPrimaryNavElItem(item))).classList.remove('subnav-is-active');
        document.getElementById(getPrimaryNavItemId(getSubNavElPrimaryNavElItem(item))).setAttribute('aria-expanded', false);
        const elSubNav = document.querySelector(`.nmx-subnav-${getSubNavElPrimaryNavElItem(item)}`);
        elSubNav.classList.remove('is-active');
      });
      // close all subnav-level2
      subNavContainerLevel2BackEls.forEach((item) => {
        document.getElementById(getSubNavItemId(getSubNavLevel2ElSubNavElItem(item))).classList.remove('subnav-level2-is-active');
        document.getElementById(getSubNavItemId(getSubNavLevel2ElSubNavElItem(item))).setAttribute('aria-expanded', false);
        const elSubNavLevel2 = document.querySelector(`.nmx-subnav-level2-${getSubNavLevel2ElSubNavElItem(item)}`);
        elSubNavLevel2.classList.remove('is-active');
      });
    }
  };

  // const navCtaStateReset = (nav) => { // TODO: not currently used
  //   const focusableEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link'))
  //   focusableEls.forEach((item) => {
  //     item.classList.remove('has-focus')
  //     item.classList.remove('has-hover')
  //   })
  //   // console.log('document.activeElement', document.activeElement)
  //   if (document.activeElement.id === 'nmx-nav-link-utility-search') {
  //     document.activeElement.blur()
  //   }
  // }

  // Mobile Nav Activate Primary Function

  const activateMobileNav = (nav, mobileTriggerOpen, mobileOpenOverlay, headerLogoContainer, loginButtonContainer, pageLoadWindowWidth, pageLoadWindowHeight) => {
    const focusableElsPrimary = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary'));
    const focusableElsSubnavHasSubnavLevel2 = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link-primary-sub.has-subnav-level2'));
    const subNavContainerBackEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link-mobile-back'));
    const subNavContainerLevel2BackEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link-mobile-level2-back'));

    // TODO: ensure nmx-nav-link-mobile-subnav-overview is not indexed in desktop menu and proper ARIA role in mobile menu

    // kill all potential "open nav" features by simulating click on mobileTriggerClose
    // additional desktop nav deactivations built into mobileTriggerClose function
    // mobileTriggerClose.click()

    // build mobile logo positioning
    if (!headerLogoContainer.parentElement.classList.contains('nmx-header-mobile-container')) {
      document.querySelector('.nmx-header-mobile-container').appendChild(headerLogoContainer);
    }

    // build mobile login button positioning
    if (loginButtonContainer && !loginButtonContainer.parentElement.classList.contains('nmx-header-mobile-container')) {
      document.querySelector('.nmx-header-mobile-container').appendChild(loginButtonContainer);
    }

    // nav and body scrolling
    navBodyScrollingHandler(pageLoadWindowWidth, pageLoadWindowHeight);

    // activate mobile subnav
    focusableElsPrimary.forEach((item) => {
      item.addEventListener('click', mobilePrimaryFocusableElsClickHandler);
    });

    // activate mobile subnav level2
    focusableElsSubnavHasSubnavLevel2.forEach((item) => {
      item.addEventListener('click', mobileSubnavFocusableElsClickHandler);
    });

    // find subnav back button and deactivate associated subnav
    subNavContainerBackEls.forEach((item) => {
      item.addEventListener('click', mobileSubnavBackElsClickHandler);
    });

    // find subnav level2 back button and deactivate associated subnav level2
    subNavContainerLevel2BackEls.forEach((item) => {
      item.addEventListener('click', mobileSubnavLevel2BackElsClickHandler); // TODO: if landscape, reset scrollTop
    });

    // mobile trigger open
    mobileTriggerOpen.addEventListener('click', mobileTriggerOpenElClickHandler);

    // mobile open overlay
    mobileOpenOverlay.addEventListener('click', mobileOpenOverlayElClickHandler);
  };

  // Mobile Nav Deactivate Primary Function

  const deactivateMobileNav = (nav, mobileTriggerOpen, mobileOpenOverlay) => {
    const focusableElsPrimary = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link.nmx-nav-link-primary'));
    const focusableElsSubnavHasSubnavLevel2 = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link-primary-sub.has-subnav-level2'));
    const subNavContainerBackEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link-mobile-back'));
    const subNavContainerLevel2BackEls = Array.prototype.slice.call(nav.querySelectorAll('.nmx-nav-link-mobile-level2-back'));
    const mobileSubnavLevel1 = Array.prototype.slice.call(nav.querySelectorAll('.nmx-subnav'));
    const mobileSubnavLevel2 = Array.prototype.slice.call(nav.querySelectorAll('.nmx-subnav-level2'));

    // kill all potential "open nav" features by simulating click on mobileTriggerClose
    // mobileTriggerClose.click()

    // remove any potential mobile nav property styles
    nav.style.height = `${117}px`;
    nav.style.overflowY = 'visible';
    nav.classList.remove('is-mobile-active');
    document.body.classList.remove('is-mobile-active');
    mobileSubnavLevel1.forEach((item1) => {
      // if (document.documentElement.classList.contains('nmx-no-touch')) {
      // reset subnav level 1
      item1.style.height = 'auto';
      item1.style.overflowY = 'auto';
      // }
    });
    mobileSubnavLevel2.forEach((item2) => {
      // if (document.documentElement.classList.contains('nmx-no-touch')) {
      // reset mobile nav level 2
      item2.style.height = 'auto';
      item2.style.overflowY = 'initial';
      // }
    });

    // activate mobile subnav
    focusableElsPrimary.forEach((item) => {
      item.removeEventListener('click', mobilePrimaryFocusableElsClickHandler);
    });

    // activate mobile subnav level2
    focusableElsSubnavHasSubnavLevel2.forEach((item) => {
      item.removeEventListener('click', mobileSubnavFocusableElsClickHandler);
    });

    // find subnav back button and deactivate associated subnav
    subNavContainerBackEls.forEach((item) => {
      item.removeEventListener('click', mobileSubnavBackElsClickHandler);
    });

    // find subnav level2 back button and deactivate associated subnav level2
    subNavContainerLevel2BackEls.forEach((item) => {
      item.removeEventListener('click', mobileSubnavLevel2BackElsClickHandler);
    });

    // mobile trigger open
    mobileTriggerOpen.removeEventListener('click', mobileTriggerOpenElClickHandler);

    // mobile open overlay
    mobileOpenOverlay.removeEventListener('click', mobileOpenOverlayElClickHandler);
  };

  // ===============================
  // Nav Init
  // ===============================

  ready(() => {
    // small delay for react issue
    setTimeout(() => {
      // DOM definitions
      const headerContainer = document.getElementById('nmx-header');
      const skipNav = document.getElementById('nmx-skip-nav');
      const primaryNav = document.getElementById('nmx-nav-primary');
      const mobileNav = document.getElementById('nmx-mobile-nav-container');
      const mobileTriggerOpen = document.getElementById('nav-toggle');
      const mobileOpenOverlay = document.querySelector('.nmx-mobile-nav-overlay');
      const headerLogoContainer = document.querySelector('.nmx-header-logo-container');
      const loginButtonContainer = document.querySelector('.nmx-login-button');
      const utilityNavFafaBtn = document.getElementById('nmx-nav-link-utility-fafa');
      const utilityNavClaimsBtn = document.getElementById('nmx-nav-link-utility-claims');

      // device type check
      // temporary device type check // TODO: remove before going to prod
      if (checkDeviceType() === 'desktop') {
      // add to body class if desktop
        document.documentElement.classList.add('nmx-no-touch');
      } else {
        document.documentElement.classList.remove('nmx-no-touch');
      }

      // viewport definitions
      let thisBodyWidth = document.body.offsetWidth;
      // let thisBodyHeight = document.body.offsetHeight
      const mmLarge = window.matchMedia('(min-width: 1024px)'); // TODO: bake in checkDesktopLargeDeviceWidth() ?
      const mmLarge1200 = window.matchMedia('(min-width: 1200px)'); // TODO: bake in checkDesktopLargeDeviceWidth() ?

      const mmCheck = (pageLoadWindowWidth, pageLoadWindowHeight) => {
        if (mmLarge.matches) {
        // ===============
        // desktop actions
        // ===============

          // Dectivate Mobile Nav
          deactivateMobileNav(mobileNav, mobileTriggerOpen, mobileOpenOverlay);

          // Activate Primary Nav
          activateDesktopPrimaryNav(primaryNav, headerLogoContainer, loginButtonContainer); // TODO: enable on desktop only?

          // switch accessibility role for primary menu
          document.querySelector('#nmx-nav-primary > ul').setAttribute('role', 'menubar');

          // set Claims and Fafa utility nav buttons
          if (utilityNavFafaBtn) {
            utilityNavFafaBtn.classList.remove('nmx-button', 'nmx-button--secondary');
          }
          if (utilityNavClaimsBtn) {
            utilityNavClaimsBtn.classList.remove('nmx-button', 'nmx-button--tertiary');
          }
        } else {
        // ===============
        // mobile actions
        // ===============

          // Activate Mobile Nav
          activateMobileNav(mobileNav, mobileTriggerOpen, mobileOpenOverlay, headerLogoContainer, loginButtonContainer, pageLoadWindowWidth, pageLoadWindowHeight);

          // Deactivate Desktop Nav
          deactivateDesktopPrimaryNav(primaryNav);

          // switch accessibility role for primary menu
          // TODO: dial in this selector
          document.querySelector('#nmx-nav-primary > ul').setAttribute('role', 'menu');

          // set Claims and Fafa utility nav buttons
          utilityNavFafaBtn.classList.add('nmx-button', 'nmx-button--secondary');
          utilityNavClaimsBtn.classList.add('nmx-button', 'nmx-button--tertiary');
        }
      };

      // if logo-only header, disable all primary nav functionality
      if (!headerContainer.classList.contains('is-logo-only')) {
        mmLarge.addListener(mmCheck, throttle(() => { }, LISTENER_THROTTLE)); // TODO: addListener deprecated
        mmCheck(pageLoadWindowWidthParam, pageLoadWindowHeightParam);
      }

      const checkWindowResize = () => {
        switch (checkDesktopLargeDeviceWidth()) {
          case false:
          // set body width var needed for header logo positioning
            thisBodyWidth = document.body.offsetWidth;
            // set body height var needed for login modal height adjustment
            // thisBodyHeight = document.body.offsetHeight
            // mobile sizing
            headerLogoContainer.setAttribute('style', `width: ${thisBodyWidth}px; max-width: ${thisBodyWidth}px;`);

            // reset primaryNav left margin // if logo-only header, disable all primary nav functionality
            if (!headerContainer.classList.contains('is-logo-only')) {
              primaryNav.style.marginLeft = 0;
            }
            // reset all focus and hover states
            // navCtaStateReset(mobileNav)
            // reset scrollTop
            mobileNav.scrollTop = 0;
            // handle nav and body scrolling // if logo-only header, disable all primary nav functionality
            if (!headerContainer.classList.contains('is-logo-only')) {
              navBodyScrollingHandler();
            }
            // if active informational banner and rendered to DOM, handle resize
            if (headerContainer.classList.contains('nmx-informational-banner-active') && document.getElementById('nmx-informational-banner') !== null) {
              const bannerEl = document.getElementById('nmx-informational-banner');
              if (headerContainer.classList.contains('is-logo-only')) {
                headerLogoContainer.setAttribute('style', 'top: 0;');
              } else {
                headerLogoContainer.setAttribute('style', `top: ${bannerEl.clientHeight}px;`);
              }
            }
            break;
          case true:
          // reset desktop sizing
            headerLogoContainer.setAttribute('style', 'width: auto; max-width: unset;');
            // check desktop large positioning // if logo-only header, disable all primary nav functionality
            if (!headerContainer.classList.contains('is-logo-only')) {
              if (mmLarge1200.matches === true) {
                desktopLargeHorizontalPosition(primaryNav);
              } else {
                primaryNav.style.marginLeft = `${10}px`;
              }
            }
            // reset all focus and hover states
            // navCtaStateReset(mobileNav)

            break;
          default:
        // no breakpoint registered
        }
      };

      window.addEventListener('resize', debounce(() => { // TODO: run checkDeviceType, too!!!! (getting keyboard issues with Google inspector)
        checkWindowResize();
      }, LISTENER_THROTTLE));

      // init page
      activateSkipNav(skipNav);
      setAriaCurrent();
      checkWindowResize();
      switch (checkDesktopLargeDeviceWidth()) {
        case false:
          break;
        case true:
        // if logo-only header, disable all primary nav functionality
          if (!headerContainer.classList.contains('is-logo-only')) {
            if (mmLarge1200.matches === true) {
              desktopLargeHorizontalPosition(primaryNav);
            } else {
              primaryNav.style.marginLeft = `${10}px`;
            }
          }
          break;
        default:
          break;
      }
    }, 10);
  });
};

