import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Button from '../../../base/Button';
import Link from '../../../base/Link';
import { getColorForName } from '../../../theme/colors';

export const ListItemStyled = styled.li`
 ${({ theme }) => css`
  margin-bottom: ${rem(10)};
  /* style overrides to remove OneTrust button styles, set redundantly as a fallback */
  #ot-sdk-btn.ot-sdk-show-settings.nmx-override {
    color: ${getColorForName('color-neutral-gray-29')};
    background-color: unset;
    border: unset;
    height: unset;
    white-space: unset;
    word-wrap: unset;
    padding: unset;
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    cursor: pointer;
    transition: unset;
    &:hover {
      color: ${theme.colors.primary.blue};
      background-color: unset;
    }
  `}
}`;

export const LinkStyled = styled(Link)`
  color: ${getColorForName('color-neutral-gray-29')};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
`;

// setting appropriate styles for the cookie button redundantly as a fallback
export const ButtonLinkStyled = styled(Button)`
  ${({ theme }) => css`
    && {
      color: ${getColorForName('color-neutral-gray-29')};
      padding: 0;
      min-width: unset;
      min-height: unset;
      text-align: left;
      font-size: ${rem(14)};
      line-height: ${rem(22)};
      cursor: pointer;
      :hover {
        text-decoration: underline;
        color: ${theme.colors.primary.blue};
      }
    `}
}`;

